.navBar {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    padding: 1rem 0;
    display: flex;
    align-items: center;

    img {
        width: 50px;
        height: auto;
        margin-left: 1rem;
    }

    ul {
        margin: 0 auto;
        width: 90%;
        text-align: right;

        li {
            display: inline-block;
            border-radius: 10px;
            transition: .5s ease background-color;

            &:hover {
                background-color: var(--hover);
            }
        }

        a {
            display: inline-block;
            font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
            padding: 1rem 1rem;
            font-size: 1.5rem;
            color: var(--offblack);
            outline: none;
        }

        .active {
            color: var(--purple);
            text-decoration: underline;
        }
    }

    .mobile-menu-icon {
        position: absolute;
        right: 1rem;
        top: 1rem;
        width: 2rem;
        margin: 0 auto;
        cursor: pointer;
        outline: none;
        display: none;

        #menubutton {
            width: 30px;
            height: 30px;
        }
    }

    .close-menu-icon {
        display: none;
    }

    @media only screen and (max-width: 999px) {
        .mobile-menu-icon {
            display: block;
        }

        .hide-items {
            transform: translateX(150%);
        }

        .nav-items {
            transition: .5s ease transform;
            background-color: white;
            outline: auto;
            outline-color: var(--purple);
            padding: 1rem;
            width: 35%;
            max-width: 300px;
            border-radius: 10px;
            position: absolute;
            right: 1rem;
            top: 1rem;

            .close-menu-icon {
                display: block;
                margin: 0 0 0 auto;
                cursor: pointer;
                padding: 0;
                width: 30px;
                height: 30px;

                * {
                    pointer-events: none;
                }

                #closebutton {
                    margin: 0;
                    padding: 0;
                    width: 30px;
                    height: 30px;
                }
            }

            li {
                display: block;
                margin-bottom: 1rem;
                text-align: center;
            }
        }

    }
}

.sticky {
    top: 0;
    z-index: 100;
    background-color: #9198e5fe;
    transition: .5s ease background-color;
}