#cell {
  display: flex;
  flex-direction: column;
  width: 32%;
  align-items: center;

  p {
    text-align: center;
    font-size: 20px;
  }

  img {
    height: 6rem;
    margin-bottom: 5%;
  }
}

h2 {
  text-decoration: none;
}

@media only screen and (max-width: 999px) {
  #cell {
    width: 100%;

    p {
      text-align: center;
      font-size: 16px;
    }

    img {
      height: 6rem;
      margin-bottom: 5%;
    }
  }

}