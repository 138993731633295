.projectCell {
  border-radius: 20px;
  transition: 0.5s;
  background-color: #F9F9FE;
  padding: 8%;


  img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }
}