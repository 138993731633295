.projectModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  width: 100vw;
  height: 100vh;
  background-color: rgba(25, 26, 26, 0.776);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn;
  animation-duration: 0.5s;
}

.hidden {
  display: none;
}

.modalContainer {
  position: relative;
  width: 80vw;
  height: 90vh;
  min-height: 600px;
  background-color: white;
  opacity: 1;
  border-radius: 20px;
  display: flex;
  padding: 5%;

  img {
    width: 30vw;
    height: 37.5vw;
    animation: fadeInLeft;
    animation-duration: 1s;
    margin-right: 5%;
  }

  #closeButton {
    top: 10px;
    right: 10px;
    position: absolute;
    width: 30px;
    height: 30px;
    color: gray;

    &:hover {
      color: red;
      scale: 1.04;
      transition: 0.5s;
    }
  }

  #github {
    bottom: 20px;
    right: 20px;
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    box-shadow: 2px 2px 2px 1px gray;
    fill: black;
    margin: 10px;

    &:hover {
      fill: #6d78f3;
      scale: 1.04;
      transition: 0.5s;
    }
  }
}

.modalTextContent {
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: left;

  align-items: start;

  #title {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    text-align: left;
    font-size: 28px;
  }

  #subtitle {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    text-align: left;
    font-size: 20px;
    padding-bottom: 10px;
  }

  #date {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    text-align: left;
    font-size: 20px;
    font-weight: normal;
    padding-bottom: 20px;
  }

  p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 18px;
  }

  #skillsList {
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    li {
      color: white;
      background-color: #9198e5;
      padding: 5px;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 20px;
      border-radius: 20px;
    }
  }

}

@media only screen and (max-width: 999px) {
  .modalTextContent {
    #title {
      text-align: left;
      font-size: 28px;
    }

    #subtitle {
      text-align: left;
      font-size: 20px;
    }

    p {
      font-size: 18px;
    }
  }

  .modalContainer {
    width: 90vw;
    height: 90vh;

    img {
      display: none;
    }
  }
}