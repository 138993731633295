#connect {
  background-color: #F9F9FE;
  padding: 5%;
  align-items: center;
  justify-content: center;

  p {
    margin: auto;
    text-align: center;
  }
}

#icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.footerIcon {
  fill: black;
  border: 1px black solid;
  border-radius: 10px;
  padding: 10px;
  width: 60px;
  height: 60px;

  &:hover {
    fill: #6d78f3;
    border: 2px #6d78f3 solid;
    transition: 0.5s;
  }
}