.expCell {
  display: flex;
  position: relative;
  flex-direction: row;
  border-radius: 20px;
  box-shadow: 1px 1px 1px 1px rgb(233, 233, 233);
  min-height: 250px;
  background-color: white;

  &:hover {
    scale: 1.015;
    transition: 0.3s;
    box-shadow: 2px 2px 2px 2px rgb(203, 203, 203);
  }

  #link {
    width: 100px;
    position: absolute;
    right: 0px;
    top: 10%;
  }

  img {
    width: 190px;
    height: 190px;
    margin: 2%;
  }
}

.expContent {
  margin: 2%;

  #title {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: bold;
    color: black;
    font-size: 24px;
  }

  #org {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: black;
    font-size: 22px;
  }

  #date {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: black;
    font-size: 22px;
  }

  #text {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 20px;
  }
}

@media only screen and (max-width: 999px) {

  .expCell {
    #link {
      width: 100px;
      position: absolute;
      right: 0px;
      top: 10%;
    }

    img {
      width: 100px;
      height: 100px;
      margin: 2%;
    }
  }

  .expContent {
    #title {
      font-size: 20px;
    }

    #org {
      font-size: 18px;
    }

    #date {
      font-size: 18px;
    }

    #text {
      font-size: 16px;
    }
  }
}