#hero {
    display: flex;
    justify-content: center;
    padding: 10%;
    padding-top: 25%;
    padding-bottom: 20%;
    height: 100vh;
    background: linear-gradient(#9198E5, #F9F9FE);
}

.hidden2 {
    opacity: 0;
}

.shown {
    opacity: 1;
}

.heroContent {
    max-width: 40rem;
    align-content: center;
    animation: fadeInDown;
    animation-duration: 0.5s;
    margin-right: 5%;
}

.profile {
    width: 20vw;
    height: 20vw;
    border-radius: 50%;
    animation: fadeInRight;
    animation-duration: 0.5s;
    animation-delay: 0.5s;
}

.cornell {
    color: #B31B1B;
    font-weight: 500;
}

.hello {
    font-size: 2rem;
}

.introText {
    font-size: 3.5rem;
    font-weight: bold;
    text-align: left;
    align-self: flex-start
}

.introPara {
    font-size: 1.5rem;
    text-align: left;
}

@media only screen and (max-width: 999px) {

    .hello {
        font-size: 1.5rem;
    }

    .introText {
        font-size: 2rem;
        font-weight: bold;
    }

    .introPara {
        font-size: 1rem;
    }

    .profile {
        min-width: 150px;
        min-height: 150px;
        max-width: 150px;
        max-height: 150px;
    }

}