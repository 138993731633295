.horizontal {
  display: none;
}

@media only screen and (max-width: 999px) {
  .horizontal {
    padding: 0 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 5%;
    row-gap: 40px;

    svg {
      width: 15vw;
      height: 15vw;
    }
  }
}