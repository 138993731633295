.projectSection {
  background-color: #F0F1FC;
  padding: 15%;
}

#projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.project {
  border-radius: 20px;
  box-shadow: 1px 1px 1px 1px rgb(233, 233, 233);
  width: 30%;
  margin-bottom: 4%;
  position: relative;
  transition: 0.5s;

  &:hover {
    scale: 1.04;
    transition: 0.3s;
    box-shadow: 2px 2px 2px 2px rgb(203, 203, 203);
  }
}

@media only screen and (max-width: 800px) {
  #projects {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
  }

  .project {
    border-radius: 20px;
    box-shadow: 4px 4px 4px 4px gray;
    width: 100%;
    margin: auto;
    margin-bottom: 8%;
    position: relative;
    transition: 0.5s;
  }
}