#skills {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10% 15%;
  padding-bottom: 0;
  gap: 10px;
}

#skillsLang {
  background-color: #F0F1FC;
}

@media only screen and (max-width: 999px) {
  #skills {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10%;
    gap: 20px;
  }
}