#experiences {
  background-color: #F9F9FE;
  padding: 10% 15%;
}

#expCells {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

@media only screen and (max-width: 999px) {
  #experiences {
    background-color: #F9F9FE;
    padding: 10% 5%;
  }
}