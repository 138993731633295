#header {
    margin: auto;
    padding: 15%;
    padding-right: 5%;
    padding-bottom: 10%;
    display: flex;
    justify-content: center;
    align-self: center;
    background: #F9F9FE;
}

h1 {
    text-decoration: none;
    font-weight: normal;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 40px;
    float: left;
    text-align: left;
}

.content {
    position: relative;
    width: 50vw;
}

p {
    font-size: 22px;
    text-align: left
}

.imageCollection {
    margin-left: 5%;
    width: 50vw;
    position: relative;

    img {
        border-radius: 50%;
    }
}


#img1 {
    position: absolute;
    top: 25px;
    left: 0px;
    width: 210px;
}

#img2 {
    position: absolute;
    width: 250px;
    top: 150px;
    left: 210px;
}

#img3 {
    position: absolute;
    width: 180px;
    top: 275px;
    left: 20px;
}

@media only screen and (max-width: 999px) {
    h1 {
        font-size: 28px;
    }

    p {
        font-size: 18px;
    }

    #header {
        margin: auto;
        padding: 5%;
        flex-direction: column;
        align-items: center;
        align-self: center;
    }

    .content {
        width: 80vw;
    }

    .imageCollection {
        width: 80vw;
        height: 460px;
        align-items: center;

        img {
            border-radius: 50%;
        }
    }
}